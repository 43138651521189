import { Button, Typography, theme } from 'antd'
import { accessToken, env, getShopId, TranslateFunction, useTranslate, useWindowDimensions } from 'utils'
import {
    TeamOutlined,
    CheckOutlined,
    RightOutlined,
    UserOutlined,
    EnvironmentOutlined,
    CustomerServiceOutlined,
    RetweetOutlined,
    CalendarOutlined,
    ShoppingOutlined,
    QuestionOutlined,
    SettingOutlined
} from '@ant-design/icons'
import { HorizontalContainer, Loading, PageError, VerticalContainer } from 'components/styled'
import { FormSection } from 'components/form-section/form-section'
import { Avatar } from 'components/avatar/avatar'
import { PermissionType } from 'types'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAppPermissions, useInstalledApp } from 'api'
import { useEffect } from 'react'
const { Title, Text, Paragraph } = Typography

export const AppPermissionPage = () => {

    //hooks:
    const navigate = useNavigate()
    const [ searchParams ] = useSearchParams()
    const id          = searchParams.get('app_id')
    const redirectURI = searchParams.get('redirect_uri')
    const clientId    = searchParams.get('client_id')

    //if no payload:
    useEffect(() => {
        (!id || !redirectURI || !clientId) && navigate('/404')
    }, [id, redirectURI, clientId])

    return (id && redirectURI && clientId) ? (
        <AppPermission
            id={id}
            redirectURI={redirectURI}
            clientId={clientId}
        />
    ) : <></>

}

type Props = {
    id: string,
    redirectURI: string,
    clientId: string
}

const AppPermission = ({ id, redirectURI, clientId }: Props) => {

    //hooks:
    const { __ } = useTranslate()
    const { SM, LG } = useWindowDimensions()
    const navigate = useNavigate()
    const { token: {
        paddingXXS,
        paddingXS,
        padding,
        paddingLG,
        paddingXL,
        marginXS,
        marginSM,
        marginLG,
        fontSize,
        fontSizeLG,
        borderRadiusLG,
        colorBorder,
        colorBgSpotlight,
        colorText
    }} = theme.useToken()
    
    //app:
    const { data: app, isLoading: isAppLoading } = useInstalledApp(id, () => navigate('/404'))
    const { data: permissions, isLoading: isPermissionsLoading } = useAppPermissions(id, () => navigate('/404'))

    const redirectToBackend = (isAccepted: boolean) => {
        const status = isAccepted ? 'accept-permissions' : 'deny-permissions'
        window.location.assign(`${env().REST_API_BASE_URL}/oauth/${status}?redirect_uri=${redirectURI}&client_id=${clientId}&shop_id=${getShopId()}&token=${accessToken()}`)
    }

    return (isAppLoading || isPermissionsLoading) ? <Loading/> : (!app || !permissions) ? <PageError/> : (
        <FormSection style={{
            padding: `
                ${SM ? paddingXL : 0}px
                ${LG ? paddingXS : 0}px
                ${SM ? paddingLG : 0}px
                ${LG ? paddingXS : 0}px
            `
        }}>

            <VerticalContainer style={{ gap: marginLG }}>

                {/* title, icon and description */}
                <VerticalContainer style={{ gap: marginSM }}>
                    
                    <Title level={3} style={{ margin: 0 }}>
                        {__`grant_access`}
                    </Title>

                    <HorizontalContainer style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start'
                    }}>
                        
                        {app.icon.path && (
                            <img
                                src={app.icon.path}
                                width={32}
                                height={32}
                            />
                        )}

                        <Paragraph style={{
                            margin: 0,
                            paddingTop: paddingXXS,
                            fontSize: fontSizeLG
                        }}>
                            {__(`app_name_wants_the_permission_to_access_the_resources_below`, { 'appName': app.name })}
                        </Paragraph>
                    </HorizontalContainer>

                </VerticalContainer>

                {/* permissions */}
                <VerticalContainer style={{ gap: marginXS }}>

                    {permissions.map(permission => {
                        const splittedPermission = permission.split(':')
                        if(splittedPermission.length === 3){
                            const permissionType = splittedPermission[0]
                            const permissionLevel = splittedPermission[2]
                            return (
                                <HorizontalContainer
                                    key={permission}
                                    style={{
                                        padding: `${paddingXXS}px ${SM ? paddingLG : padding}px ${paddingXXS}px ${SM ? paddingXXS : 0}px`,
                                        border: `solid 1px ${colorBorder}`,
                                        borderRadius: borderRadiusLG,
                                        alignItems: 'center'
                                    }}
                                >

                                    {/* avatar */}
                                    <Avatar
                                        name={getPermissionTitle(permissionType, __)}
                                        backgroundColor={colorBgSpotlight}
                                        shape='circle'
                                        image={
                                            <div style={{ color: colorText }}>
                                                {getPermissionIcon(permissionType)}
                                            </div>
                                        }
                                    />

                                    {/* level */}
                                    <Text style={{ whiteSpace: 'nowrap' }}>
                                        {__(permissionLevel.toLocaleLowerCase())}
                                    </Text>

                                </HorizontalContainer>
                            )
                        }else{
                            return <PageError key={permission}/>
                        }
                    })}

                </VerticalContainer>

                <HorizontalContainer style={{
                    width: '100%',
                    marginTop: marginLG,
                    justifyContent: 'flex-end'
                }}>

                    <HorizontalContainer>

                        {/* decline */}
                        <Button
                            type='text'
                            size='large'
                            onClick={() => redirectToBackend(false)}
                        >
                            {__`decline`}
                        </Button>

                        {/* accept */}
                        <Button
                            type='primary'
                            size='large'
                            onClick={() => redirectToBackend(true)}
                        >
                            {__`accept_permissions`}
                            <RightOutlined style={{ fontSize }}/>
                        </Button>

                    </HorizontalContainer>

                </HorizontalContainer>

            </VerticalContainer>
            
        </FormSection>
    )
    
}

const getPermissionTitle = (name: string, __: TranslateFunction) => {
    switch(name){
        case PermissionType.TeamMember:
            return __`team_member`
        default:
            return __(name.toLocaleLowerCase())
    }
}

const getPermissionIcon = (name: string) => {
    switch(name){
        case PermissionType.Shop:
            return <ShoppingOutlined/>
        case PermissionType.User:
            return <UserOutlined/>
        case PermissionType.Location:
            return <EnvironmentOutlined/>
        case PermissionType.TeamMember:
                return <TeamOutlined/>
        case PermissionType.Service:
            return <CustomerServiceOutlined/>
        case PermissionType.Customer:
            return <UserOutlined/>
        case PermissionType.Appointment:
            return <CheckOutlined/>
        case PermissionType.Calendar:
            return <CalendarOutlined/>
        case PermissionType.Flow:
            return <RetweetOutlined/>
        case PermissionType.Settings:
            return <SettingOutlined/>
        default:
            return <QuestionOutlined/>
    }
}
