export type PermissionTree = {
    [PermissionType.System]: {
        [PermissionLevel.Read  ]: [PermissionType, PermissionLevel][]
        [PermissionLevel.Write ]: [PermissionType, PermissionLevel][]
        [PermissionLevel.Manage]: [PermissionType, PermissionLevel][]
    }
    [PermissionType.Shop]: {
        [PermissionLevel.Read  ]: [PermissionType, PermissionLevel][]
        [PermissionLevel.Write ]: [PermissionType, PermissionLevel][]
        [PermissionLevel.Manage]: [PermissionType, PermissionLevel][]
    }
    [PermissionType.TeamMember]: {
        [PermissionLevel.Read  ]: [PermissionType, PermissionLevel][]
        [PermissionLevel.Write ]: [PermissionType, PermissionLevel][]
        [PermissionLevel.Manage]: [PermissionType, PermissionLevel][]
    }
    [PermissionType.Service]: {
        [PermissionLevel.Read  ]: [PermissionType, PermissionLevel][]
        [PermissionLevel.Write ]: [PermissionType, PermissionLevel][]
        [PermissionLevel.Manage]: [PermissionType, PermissionLevel][]
    }
    [PermissionType.Location]: {
        [PermissionLevel.Read  ]: [PermissionType, PermissionLevel][]
        [PermissionLevel.Write ]: [PermissionType, PermissionLevel][]
        [PermissionLevel.Manage]: [PermissionType, PermissionLevel][]
    }
    [PermissionType.Appointment]: {
        [PermissionLevel.Read  ]: [PermissionType, PermissionLevel][]
        [PermissionLevel.Write ]: [PermissionType, PermissionLevel][]
        [PermissionLevel.Manage]: [PermissionType, PermissionLevel][]
    }
}

export enum PermissionType {
    System = 'System',
    Shop = 'Shop',
    User = 'User',
    Location = 'Location',
    TeamMember = 'TeamMember',
    Service = 'Service',
    Customer = 'Customer',
    Appointment = 'Appointment',
    Calendar = 'Calendar',
    Flow = 'Flow',
    Settings = 'Settings'
}

export const PermissionsUiWhiteList = [
    PermissionType.Shop,
    PermissionType.TeamMember,
    PermissionType.Service,
    PermissionType.Flow,
]

export enum PermissionLevel {
    Read = 'Read',
    Write = 'Write',
    Manage = 'Manage'
}
