import { UserFlowPermissionItem, UserResourcePermissionItem, UserServicePermissionItem, UserShopPermissionItem } from '../user-permission-item/user-permission-item'
import { UserPermissionGroup } from '../user-permission-group/user-permission-group'
import { useTranslate, useWindowDimensions } from 'utils'
import { Drawer, theme } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { FormSection } from 'components/form-section/form-section'
import { Loading, NotFound, PageError, VerticalContainer } from 'components/styled'
import { UserPermissionRouter } from 'components/user/user-permission-router/user-permission-router'
import { useEffect, useState } from 'react'
import { PermissionLevel, PermissionType, PermissionTree } from 'types'
import { useField } from 'react-final-form'
import { usePermissionTree } from 'api'

type GroupedPermissions = {
    [PermissionType.Shop      ]: string[]
    [PermissionType.TeamMember]: string[]
    [PermissionType.Service   ]: string[]
    [PermissionType.Flow      ]: string[]
}

export type InheritedPermissions = [PermissionType, PermissionLevel][]

export const UserPermissionList = () => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { margin, paddingLG, colorTextDescription, colorErrorText, fontSize } } = theme.useToken()

    //window dimensions:
    const { width, SM } = useWindowDimensions()

    //permission tree:
    const { data: permissionTree, isLoading } = usePermissionTree()

    //permissions:
    const { input: { value: permissions, onChange: updatePermissions }, meta: { touched, error } } = useField('permissions')

    //state:
    const [ isDrawerOpen, setIsDrawerOpen ] = useState(false)
    const [ groupedPermissions, setGroupedPermissions] = useState(groupPermissions(permissions))
    const [ inheritedPermissions, setInheritedPermissions ] = useState([] as InheritedPermissions)

    //group permissions:
    useEffect(() => setGroupedPermissions(groupPermissions(permissions)), [permissions])

    //inherited permissions:
    useEffect(() => {
        let inheritedPermissions: [PermissionType, PermissionLevel][] = []
        if(permissions.length > 0 && permissionTree){
            inheritedPermissions = findInheritedPermissions(permissions, permissionTree)
        }
        setInheritedPermissions(inheritedPermissions)
    }, [ permissions, permissionTree ])

    //render:
    return (
        <FormSection
            title={__`permissions`}
            topButton={{
                label: __`grant_permission`,
                icon: <PlusOutlined/>,
                onClick: () => setIsDrawerOpen(true)
            }}
            style={{ paddingBottom: SM ? paddingLG : 0 }}
        >

            {hasNoPermission(groupedPermissions) ?
                
                <NotFound
                    message={__`user_has_no_permissions`}
                    style={{
                        color: (touched && error) ? colorErrorText : colorTextDescription,
                        paddingBottom: paddingLG,
                        fontSize: fontSize
                    }}
                /> :

                isLoading ? <Loading/> : !permissionTree ? <PageError/> :

                    <VerticalContainer style={{ gap: margin }}>

                        {/* shop */}
                        {groupedPermissions.Shop.length > 0 &&
                            <UserPermissionGroup title={__`shop`}>
                                {groupedPermissions.Shop.map(permission =>
                                    <UserShopPermissionItem
                                        key={permission}
                                        permission={permission}
                                        onRemove={() => updatePermissions(handleRemovePermission(permissions, permission))}
                                    />
                                )}
                            </UserPermissionGroup>
                        }

                        {/* resource */}
                        {groupedPermissions.TeamMember.length > 0 &&
                            <UserPermissionGroup title={__`team_member`}>
                                {groupedPermissions.TeamMember.map(permission =>
                                    <UserResourcePermissionItem
                                        key={permission}
                                        permission={permission}
                                        onRemove={() => updatePermissions(handleRemovePermission(permissions, permission))}
                                    />
                                )}
                            </UserPermissionGroup>
                        }

                        {/* service */}
                        {groupedPermissions.Service.length > 0 &&
                            <UserPermissionGroup title={__`service`}>
                                {groupedPermissions.Service.map(permission =>
                                    <UserServicePermissionItem
                                        key={permission}
                                        permission={permission}
                                        onRemove={() => updatePermissions(handleRemovePermission(permissions, permission))}
                                    />
                                )}
                            </UserPermissionGroup>
                        }

                        {/* flow */}
                        {groupedPermissions.Flow.length > 0 &&
                            <UserPermissionGroup title={__`flow`}>
                                {groupedPermissions.Flow.map(permission =>
                                    <UserFlowPermissionItem
                                        key={permission}
                                        permission={permission}
                                        onRemove={() => updatePermissions(handleRemovePermission(permissions, permission))}
                                    />
                                )}
                            </UserPermissionGroup>
                        }

                    </VerticalContainer>
                
            }

            {/* drawer */}
            <Drawer
                closable={false}
                width={SM ? 450 : width } // full width in mobile
                styles={{ body: { padding: 0 }}}
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
            >
                
                <UserPermissionRouter
                    inheritedPermissions={inheritedPermissions}
                    onAdd={permission => updatePermissions(getNewPermissions(permissions, permission))}
                    onBack={() => setIsDrawerOpen(false)}
                />
                
            </Drawer>

        </FormSection>
    )
    
}

//initPermissionState:
const groupPermissions = (permissions: string[]) => {

    //empty state:
    const groupedPermissions = {
        [PermissionType.Shop      ]: [],
        [PermissionType.TeamMember]: [],
        [PermissionType.Service   ]: [],
        [PermissionType.Flow      ]: []
    } as GroupedPermissions
    
    //group the permissions:
    permissions.map(permission => {
        const splitPermission = permission.split(':')
        const type = splitPermission[0] as keyof GroupedPermissions
        groupedPermissions[type] = removeAllPermissionsLevels(groupedPermissions[type], permission)
        groupedPermissions[type].push(permission)
        
    })

    return groupedPermissions
}

//findInheritedPermissions:
const findInheritedPermissions = (permissions: string[], permissionTree: PermissionTree) => {
    const inheritedPermissions: [PermissionType, PermissionLevel][] = []
    permissions.map(permission => {
        const splitPermission = permission.split(':')
        const permissionType = splitPermission[0] as keyof PermissionTree
        const permissionLevel = splitPermission[2] as PermissionLevel
        permissionTree[permissionType][permissionLevel].map(per => {
            inheritedPermissions.push(per)
        })
    })
    return inheritedPermissions
}

//getTypeInheritedLevel:
export const getTypeInheritedLevel = (inheritedPermissions: [PermissionType, PermissionLevel][], type: PermissionType) => {
    let level = null
    inheritedPermissions.map(permission => {
        if(permission[0] === type){
            level = permission[1]
        }
    })
    return level
}

//hasNoPermission:
const hasNoPermission = (groupedPermissions: GroupedPermissions) => !(groupedPermissions.Shop.length || groupedPermissions.TeamMember.length || groupedPermissions.Service.length || groupedPermissions.Flow.length)

//getNewPermissions:
const getNewPermissions = (permissions: string[], newPermission: string) => {
    if(permissions.indexOf(newPermission) === -1){
        const newPermissions = removeAllPermissionsLevels(permissions, newPermission)
        newPermissions.push(newPermission)
        return newPermissions
    }
    return permissions
}

//removeAllPermissionsLevels:
const removeAllPermissionsLevels = (permissions: string[], permission: string) => {
    
    //get fields from permission:
    const splitPermission = permission.split(':')
    const type = splitPermission[0] as keyof GroupedPermissions
    const id = splitPermission[1]
    
    //remove same read:
    const newPermissions = [...permissions]
    const readIndex = newPermissions.indexOf(`${type}:${id}:${PermissionLevel.Read  }`)
    readIndex >= 0 && newPermissions.splice(readIndex, 1)
    
    //remove same write:
    const writeIndex = newPermissions.indexOf(`${type}:${id}:${PermissionLevel.Write  }`)
    writeIndex >= 0 && newPermissions.splice(writeIndex, 1)
    
    //remove same manage
    const manageIndex = newPermissions.indexOf(`${type}:${id}:${PermissionLevel.Manage  }`)
    manageIndex >= 0 && newPermissions.splice(manageIndex, 1)
    
    return newPermissions
}

//handleRemovePermission:
const handleRemovePermission = (permissions: string[], permission: string) => {
    const newPermissions = [...permissions]
    newPermissions.splice(newPermissions.indexOf(permission), 1)
    return newPermissions
}
