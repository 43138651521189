import { ShopApp, RegisteredApp, RegisteredExtension } from 'types'
import {
    accessToken,
    getRefreshTokenUserId,
    getShopId,
    refreshToken,
    requestFetchAPI,
    useMutationHandler,
    usePaginationHandler,
    useQueryHandler
} from 'utils'
import { AppTarget } from '@sesamiapp/app-message'

const shopAppQuery = `
    id
    name
    title
    description
    icon {
        id
        path
    }
`

//useAllApps:
export const useAllApps = (search: string | null, limit: number, enabled?: boolean) => usePaginationHandler<ShopApp[]>({
    query: `
        query getApps (
            
            $searchTerm: String,
            $limit: Int!,
            $after: String,
            $before: String

        ) {
            getApps(

                searchTerm: $searchTerm,
                limit: $limit,
                after: $after,
                before: $before
                
            ) {

                data {
                    ${shopAppQuery}
                }

                totalItems
                remainingItems
                nextCursor
                previousCursor
                
            }
        }
    `,
    variables: {
        searchTerm: search,
        limit,
        after: null,
        before: null
    },
    cacheKey: ['allApps'],
    limit,
    enabled,
    getResponse: json => json.data.getApps,
    onSuccess: response => response.data
})

//useInstalledApps:
export const useInstalledApps = (search: string | null, limit: number, enabled?: boolean) => {

    const shopId = getShopId()

    return usePaginationHandler<ShopApp[]>({
        query: `
            query getInstalledApps (
                
                $shop: String!,
                $searchTerm: String,
                $limit: Int!,
                $after: String,
                $before: String

            ) {
                getInstalledApps(

                    shop: $shop,
                    searchTerm: $searchTerm,
                    limit: $limit,
                    after: $after,
                    before: $before
                    
                ) {

                    data {
                        ${shopAppQuery}
                    }

                    totalItems
                    remainingItems
                    nextCursor
                    previousCursor
                    
                }
            }
        `,
        variables: {
            shop: shopId,
            searchTerm: search,
            limit,
            after: null,
            before: null
        },
        cacheKey: ['installedApps', shopId],
        limit,
        enabled,
        getResponse: json => json.data.getInstalledApps,
        onSuccess: response => response.data
    })

}

//useInstalledApp:
export const useInstalledApp = (id: string, onNotFound?: (json: any) => void) => useQueryHandler<ShopApp>({
    query: `
        query getInstalledApp (
            $id: String!
        ) {
            getInstalledApp (
                id: $id
            ) {
                ${shopAppQuery}
            }
        }
    `,
    variables: {
        id
    },
    cacheKey: ['installedApp', id],
    onSuccess: json => json.data.getInstalledApp,
    onNotFound
})

//useAppPermissions:
export const useAppPermissions = (id: string, onNotFound?: (json: any) => void) => useQueryHandler<string[]>({
    query: `
        query getAppPermissions (
            $id: String!
            $shop: String!
        ) {
            getAppPermissions (
                id: $id
                shop: $shop
            ) {
                permissions
            }
        }
    `,
    variables: {
        id,
        shop: getShopId()
    },
    cacheKey: ['appPermissions', id],
    onSuccess: json => {
        const shopId = getShopId()
        const permissions = json.data.getAppPermissions.permissions as any
        return shopId ? permissions[shopId] : []
    },
    onNotFound
})

const registeredAppQuery = `
    id
    name
    title
    icon {
        id
        path
    }
    description
    domain
    redirectURIs
    distribution
    clientId
    extensions {
        id
        name
        target
        url
    }
`

//useRegisteredApps:
export const useRegisteredApps = (search: string | null, limit: number, enabled?: boolean) => usePaginationHandler<RegisteredApp[]>({
    query: `
        query getRegisteredApps (
            
            $searchTerm: String,
            $limit: Int!,
            $after: String,
            $before: String

        ) {
            getRegisteredApps (

                searchTerm: $searchTerm,
                limit: $limit,
                after: $after,
                before: $before
                
            ) {

                data {
                    ${registeredAppQuery}
                }

                totalItems
                remainingItems
                nextCursor
                previousCursor
                
            }
        }
    `,
    variables: {
        searchTerm: search,
        limit,
        after: null,
        before: null
    },
    cacheKey: ['registeredApps', getRefreshTokenUserId(refreshToken())],
    limit,
    enabled,
    getResponse: json => json.data.getRegisteredApps,
    onSuccess: response => response.data
})

//useRegisteredApp:
export const useRegisteredApp = (id: string, onNotFound?: (json: any) => void) => useQueryHandler<RegisteredApp>({
    query: `
        query getRegisteredApp (
            $id: String!
        ) {
            getRegisteredApp (
                id: $id
            ) {
                ${registeredAppQuery}
            }
        }
    `,
    variables: {
        id
    },
    cacheKey: ['registeredApp', id],
    onSuccess: json => json.data.getRegisteredApp,
    onNotFound
})

type AppRegistrationResponse = {
    clientId: string
    clientSecret: string
}

//register:
export const useRegisterApp = (app: RegisteredApp) => {

    const shopId = getShopId()
    
    return useMutationHandler<AppRegistrationResponse | 'existingName' | 'exceedMaxApp'>({
        query: `
            mutation registerApp (
                $request: RegisterAppRequest!
            ) {
                registerApp (
                    request: $request
                ) {
                    app {
                        name
                        clientId
                    }
                    clientSecret
                }
            }
        `,
        variables: {
            request: {
                shop: shopId,
                name: app.name,
                title: app.title,
                icon: app.icon.id,
                description: app.description,
                domain: app.domain,
                redirectURIs: app.redirectURIs,
                extensions: app.extensions.map(extension => ({
                    name: extension.name,
                    target: extension.target,
                    url: extension.url
                })),
                distribution: app.distribution
            }
        },
        onSuccess: json => ({
            clientId: json.data.registerApp.app.clientId,
            clientSecret: json.data.registerApp.clientSecret
        }),
        onError: json => {
            if(json.errors[0].extensions.errors[0].constraints[0].name === 'is_unique'){
                return 'existingName'
            }else if(json.errors[0].extensions.errors[0].constraints[0].name === 'exceed_max_app'){
                return 'exceedMaxApp'
            }
        }
    })

}

//update app:
export const useUpdateApp = (app: RegisteredApp) => useMutationHandler<boolean>({
    query: `
        mutation updateApp (
            $id: String!,
            $request: UpdateAppRequest!
        ) {
            updateApp (
                id: $id,
                request: $request
            ) {
                id
            }
        }
    `,
    variables: {
        id: app.id,
        request: {
            title: app.title,
            icon: app.icon.id,
            description: app.description,
            domain: app.domain,
            redirectURIs: app.redirectURIs,
            extensions: app.extensions,
            distribution: app.distribution
        }
    },
    onSuccess: json => json.data.updateApp.id === app.id
})

//uninstall app:
export const useUninstallApp = (id: string) => useMutationHandler<boolean>({
    query: `
        mutation uninstallApp (

            $shop: String!,
            $id: String!

        ) {
            uninstallApp (

                shop: $shop,
                id: $id

            ){
                message
            }
        }
    `,
    variables: {
        shop: getShopId(),
        id,
    },
    onSuccess: json => json.data.uninstallApp.message === 'Done'
})

//getAppToken:
export const getAppToken = (appId: string, extensionId: string | null) => requestFetchAPI(
    {
        'Content-Type': 'application/json',
        'authorization': `Bearer ${accessToken()}`
    },
    `
        mutation CreateAccessToken (
            $request: AccessTokenRequest!
        ){
            CreateAccessToken (
                request: $request
            ){
                token
            }
        }
    `,
    {
        request: {
            onlineTokenRequest: {
                shop: getShopId(),
                appId,
                extensionId,
            },
            type: 'ONLINE_TOKEN'
        }
    }
)

//url:
export const useAppURL = (
    appId: string,
    extensionId: string | null,
    target: AppTarget,
    timestamp: number,
    messageId: string,
    locale: string,
    parameters: object | null
) => {
    
    const shopId = getShopId()

    return useQueryHandler<string>({
        query: `
            query getAppURL (
                $request: GetAppURLRequest!
            ) {
                getAppURL (
                    request: $request
                ) {
                    url
                }
            }
        `,
        variables: {
            request: {
                shop: shopId,
                appId,
                extensionId,
                target,
                timestamp,
                messageId,
                locale,
                parameters
            }
        },
        cacheKey: [
            'appURL',
            shopId,
            appId,
            extensionId,
            target,
            timestamp,
            messageId,
            locale,
            parameters
        ],
        onSuccess: json => json.data.getAppURL.url,
    })

}

//extensions:
export const useExtensions = (target: AppTarget[], limit: number) => {

    const shopId = getShopId()

    return usePaginationHandler<RegisteredExtension[]>({
        query: `
            query getExtensions (
                
                $shop: String!,
                $target: [AppTarget!]!
                $limit: Int!,
                $after: String,
                $before: String

            ) {
                getExtensions (

                    shop: $shop,
                    target: $target,
                    limit: $limit,
                    after: $after,
                    before: $before
                    
                ) {

                    data {
                        id
                        name
                        target
                        url
                        appId
                    }

                    totalItems
                    remainingItems
                    nextCursor
                    previousCursor
                    
                }
            }
        `,
        variables: {
            shop: shopId,
            target,
            limit,
            after: null,
            before: null
        },
        cacheKey: ['extensions', shopId, target, limit],
        limit,
        getResponse: json => json.data.getExtensions,
        onSuccess: response => response.data
    })
}
